<template>
    <div class="time" :class="[className, noMargins ? 'noMargins' : '', bigFont ? 'bigFont' : '']">
        <v-text-field :height="bigFont ? '50' : ''" maxlength="5" hide-details="auto" v-model="time" @keyup="closePopup" @focus="openPopup" @click="openPopup" append-icon="mdi-clock-outline" :label="label" :placeholder="placeholder"></v-text-field>
        <date-picker class="timePicker" v-model="time" type="time" format="HH:mm" value-type="format" ref="timePicker"/>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
    name: 'Time',
    components: {
        DatePicker
    },
    props: {
        value: {
            type: String,
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        noMargins: {
            type: Boolean,
            default: false
        },
        bigFont: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            time: this.value != null ? this.value : '',
            loadComplete: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.time = newValue != null ? newValue : '';
            }
        },
        time: {
            immediate: true,
            handler(newTime) {
                if (newTime){
                    let digits = newTime.replace(/\D/g, '');
    
                    if (digits.length >= 2) {
                        this.time = digits.slice(0, 2) + ':' + digits.slice(2, 4);
                    } else {
                        this.time = digits;
                    }
    
                    console.warn({time: this.time});
                    
                    this.$emit('input', this.time);
    
                    if (this.loadComplete) this.$emit('change', this.time);
                    this.loadComplete = true;
                }
            }
        },
    },
    methods: {
        openPopup() {
            this.$refs.timePicker.openPopup();
        },
        closePopup() {
            this.$refs.timePicker.closePopup();
        },
    }
}
</script>

<style lang="scss" scoped>

.time {
    position: relative;

    .timePicker{
        position: absolute;
        height: 45px;
        top: 0;
        visibility: hidden;
    }

}

</style>

<style lang="scss">
    .noMargins {
        .v-text-field {
            margin: 0;
            padding: 0;
        }
    }
    .bigFont {
        .v-text-field {
            font-size: 30px;
            
            .v-input__append-inner{
                align-items: center;
                padding: 0;
                margin: 0;
                height: 100%;
            }
        }
    }
</style>